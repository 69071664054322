<template>
  <div class="quality-assurance">
    <head-nav></head-nav>

    <div class="quality-assurance-banner">
      <img
        :src="$t('quality-assurance.home-banner')"
        alt=""
      >
    </div>

    <div class="quality-assurance-wrap">
      <div class="quality-assurance-list">
        <h3 class="quality-assurance-list-title">
          <img
            :src="$t('quality-assurance.title-1')"
            :style="this.$i18n.locale==='en'?'width:280px;height:auto;':''"
            alt=""
          >
        </h3>

        <div class="quality-assurance-list-imgs">
          <div class="quality-assurance-list-img">
            <img
              :src=" $t('quality-assurance.list-1.img')"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="quality-assurance-list">
        <h3 class="quality-assurance-list-title">
          <img
            :src="$t('quality-assurance.title-2')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:300px;height:auto;':''"
          >
        </h3>

        <div class="quality-assurance-list-imgs">
          <div
            class="quality-assurance-list-img"
            v-for="(item, index) in  $t('quality-assurance.assuranceInfo')"
            :key="index"
            @click.stop="onClickOpen(item.id)"
          >
            <img
              :src="item.img"
              alt=""
            >

            <div class="quality-assurance-list-img-info">
              <p>{{item.title}}</p>
            </div>

            <transition name="nameoftransition">
              <div
                v-if="detailId === item.id"
                class="listEnlargeImg"
              >
                <img
                  src="@/assets/image/x.png"
                  class="iconfont icon-cha"
                  @click.stop="onClickClose()"
                >

                <div class="list-img">

                  <div class="img-info">
                    <div
                      v-for="(items ,index) in item.list"
                      :key="index"
                    >
                      <h3>{{items.title}}</h3>
                      <div>
                        <img
                          :src="items.img"
                          alt=""
                        >
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>

          <h3 class="quality-assurance-list-title">
          <img
            :src="$t('quality-assurance.title-2')"
            alt=""
            :style="this.$i18n.locale==='en'?'width:300px;height:auto;':''"
          >
        </h3>

        <div class="quality-assurance-list-imgs">
          <div
            class="quality-assurance-list-img"
            v-for="(item, index) in  $t('quality-assurance.assuranceImg')"
            :key="index"
            @click.stop="onClickOpen(item.id)"
          >
            <img
              :src="item.img"
              alt=""
            >

            <div class="quality-assurance-list-img-info">
              <p>{{item.title}}</p>
            </div>

            <transition name="nameoftransition">
              <div
                v-if="detailId === item.id"
                class="listEnlargeImg"
              >
                <img
                  src="@/assets/image/x.png"
                  class="iconfont icon-cha"
                  @click.stop="onClickClose()"
                >

                <div class="list-img">

                  <div class="img-info">
                    <div
                      v-for="(items ,index) in item.list"
                      :key="index"
                    >
                      <h3>{{items.title}}</h3>
                      <div>
                        <img
                          :src="items.img"
                          alt=""
                        >
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>
      </div>

    </div>
    <footers></footers>
  </div>
</template>

<script>
import banner from '@/assets/image/quality-assurance/banner.png';

import HeadNav from './head-nav';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    Footers,
  },

  data() {
    return {
      detailId: 0,
      isMaskShow: false,
      qualityBanner: [
        {
          id: 1,
          imgs: banner,
        },
      ],
      qualityImgs: [],
      qualityImgsbox: [],
    };
  },

  methods: {
    onClickOpen(ID) {
      this.detailId = ID;
      this.isMaskShow = true;
      this.$store.commit('SET_LOCK_BODY_SCROLL', true);
    },
    onClickClose() {
      this.detailId = 0;
      this.isMaskShow = false;
      this.$store.commit('SET_LOCK_BODY_SCROLL', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.nameoftransition-enter-active,
.nameoftransition-leave-active {
  opacity: 1;
  transform: scale(0);
}
.nameoftransition-enter,
.nameoftransition-leave-to {
  opacity: 0;
}
.quality-assurance {
  &-banner {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
  .quality-assurance-wrap {
    padding: 40px 30px 100px 30px;
    background: #f8f8f8;

    .quality-assurance-list {
      &:nth-child(1) {
        .quality-assurance-list-img {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &:nth-child(3) {
        .quality-assurance-list-img {
          width: 100%;
          display: flex;
          flex: 1;
          justify-content: space-around;

          &:nth-child(2),
          &:nth-child(3) {
            .quality-assurance-list-img-box {
              width: 344.5px;
              background: #eee;
            }
          }

          &-box {
            width: 344.5px;
            text-align: center;
            line-height: 300px;
            background: #ffbf00;
          }
        }
      }

      &-title {
        margin: 50px 0 30px 0;
        text-align: center;

        img {
          height: 70px;
        }
      }

      &-content {
        line-height: 40px;
        font-size: 28px;
        color: #646464;
      }

      &-imgs {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        // justify-content: space-around;
        border-radius: 10px;
        overflow: hidden;

        .quality-assurance-list-img {
          width: 344.5px;
          position: relative;

          &:nth-child(1),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(8) {
            .quality-assurance-list-img-info {
              background: #666;
              opacity: 0.8;
              color: #fff;
            }
          }

          img {
            width: 100%;
            height: 300px;
          }

          &-info {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 100%;
            height: 300px;
            line-height: 300px;
            font-size: 28px;
            color: #333333;
            background: #fff;
            opacity: 0.8;
          }

          .listEnlargeImg-mask {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }

          .listEnlargeImg {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            transition: all 0.3s;

            &::-webkit-scrollbar {
              width: 12px;
              height: 12px;
            }

            .icon-cha {
              position: absolute;
              left: 44%;
              bottom: 5%;
              color: #fff;
              width: 80px;
              height: 80px;
              z-index: 999;
            }

            .list-img {
              position: absolute;
              margin-left: auto;
              max-width: 977px;
              border-radius: 3px;
              background: white;
              box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
               rgba(15, 15, 15, 0.1) 0px 5px 10px,
                rgba(15, 15, 15, 0.2) 0px 15px 40px;
              top: 36px;
              left: 31px;
              right: 31px;
              margin-right: auto;
              height: calc(100% - 240px);
              overflow: auto;
              border-radius: 20px;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .img-info {
              width: 100%;

              h3 {
                text-align: center;
                margin: 20px 0;
              }

              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list-imgs
    .quality-assurance-list-img,
  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img-box,
  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img:nth-child(2)
    .quality-assurance-list-img-box,
  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img:nth-child(3)
    .quality-assurance-list-img-box {
    width: 288px;
  }

  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img:nth-child(2n + 2)
    .quality-assurance-list-img-left {
    left: 288px;
  }
  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img:nth-child(2n + 2)
    .quality-assurance-list-img-right {
    left: -288px;
  }

  .quality-assurance
    .quality-assurance-wrap
    .quality-assurance-list:nth-child(3)
    .quality-assurance-list-img-box {
    font-size: 0.6rem;
  }
}
</style>
